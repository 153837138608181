import React from "react";
import { Layout } from "../components/Layout";
import Seo from "../components/Seo";
import { Grid } from "@mui/material";

export default function PageTemplate({pageContext}) {
    return (
        <Layout seo={<Seo />}>
            <Grid container justifyContent="center">
                <Grid item xs={10} sx={{}}>
                    {JSON.stringify(pageContext)}
                    {/*<div*/}
                    {/*  dangerouslySetInnerHTML={{ __html: pageContext.page.content.html }}*/}
                    {/*/>*/}
                </Grid>
            </Grid>
        </Layout>
    );
}
